<template>
  <div v-if="$store.state.spinner.showSpinner" class="l-mask">
    <a-spin>
      <div style="background: red">111</div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "index",
  setup() {},
};
</script>

<style scoped>
.l-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  /*text-align: center;*/
  background: rgba(0, 0, 0, 0.5);
}
</style>
